import React, { useState } from "react";
import { Button, Modal } from "antd";
import "./DriverIdentificationAssignModal.css";
import { UserResponseInfo } from "../../../types/user/userResponse.types";
import { useTranslation, Trans } from "react-i18next";



type AssignModalProps = {
  assignModal: boolean;
  vehicleName: string;
  selectedDriver?: UserResponseInfo | null;
  driverName?: string;
  onHandleCancel: () => void;
  onHandleConfirm: () => void;
};

const DriverIdentificationAssignModal: React.FC<AssignModalProps> = ({
  assignModal,
  vehicleName,
  selectedDriver,
  driverName,
  onHandleCancel,
  onHandleConfirm,
}) => {

  const formatName = (fullName: string) => {
    const [ firstName, lastName ] = fullName.split(" ");
    const lastNameInitial = lastName ? lastName.slice(0, 1).toUpperCase() : "";
    return `${firstName} ${lastNameInitial}.`
  }

  const { t } = useTranslation();

  return (
    <Modal
      title={
        t("driverIdentification.vehicleAssignmentModalTitle")
      }
      centered
      open={assignModal}
      onOk={onHandleConfirm}
      onCancel={onHandleCancel}
      className="driver-identification-assign-modal"
      okText={t("driverIdentification.confirm")}
    >
      {driverName ? (
        <Trans>
        <p style={{ whiteSpace: 'pre-line' }} >
         {t("driverIdentification.vehicleAssignmentModalText1",
            {vehicleName: vehicleName, driverName: formatName(driverName)}
          )}
        </p>
        </Trans>
      ) : (
        <p style={{ whiteSpace: 'pre-line' }} >
         {t("driverIdentification.vehicleAssignmentModalText2",
            {
              vehicleName: vehicleName,
              firstName: selectedDriver?.firstName,
              lastName: selectedDriver?.lastName.slice(0, 1),
            }
          )}
        </p>
      )}
    </Modal>
  );
};

export default DriverIdentificationAssignModal;

import React from "react";
import { Select, Spin, Tag } from "antd";
import { useTranslation } from "react-i18next";

//later we should chage this any type to a more spcific type(since this component is used in many places it needs more time to go through all of those components and double check prop types again)
type CustomSelectProps = Partial<{
    placeholder: any,
    children: any,
    handleChange: any,
    handleSearch: any,
    value: any,
    disabled: boolean,
    allowClear: boolean,
    notFound: boolean,
    onClear: any,
}>;

const CustomSelect: React.FC<CustomSelectProps> = ({ placeholder, children, handleChange, handleSearch, value, disabled, allowClear, notFound, onClear }) => {

    const { t } = useTranslation();

    return (
        <Select
            allowClear={allowClear}
            clearIcon={
                <Tag style={{ marginTop: "-20px" }} onClick={onClear && onClear} color="red">
                    {t("common.select.delete")}
                </Tag>
            }
            showSearch
            disabled={disabled}
            value={value}
            style={{ width: "100%", minWidth: "150px", color: 'black'}}
            placeholder={placeholder}
            optionFilterProp="children"
            onChange={handleChange}
            onSearch={handleSearch && handleSearch}
            defaultActiveFirstOption={false}
            notFoundContent={notFound ? <Spin size="small"/> : false}
            filterOption={false}
            dropdownMatchSelectWidth={false}
        >
            {children}
        </Select>
    );
};

CustomSelect.defaultProps = {
    disabled: false,
    allowClear: true,
};


export default CustomSelect;

import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { menu } from "./managers/pathManager";

import LoadingPage from "./components/common/container/LoadingPage";

import MandatorPage from "./components/superAdmin/mandators";
import MandatorDriveboxPage from "./components/superAdmin/mandatorDrivebox";
import MandatorSuperUserPage from "./components/superAdmin/mandatorSuperUser";
import MandatorUserPage from "./components/superAdmin/mandatorUser";
import RawDataPage from "./components/superAdmin/rawData";

import LoginPage from "./components/login";
import ResetPage from "./components/passwordreset";
import MomentPasswordResetPage from "./components/momentPasswordReset";
import DashboardPage from "./components/dashboard";

import CostCenterListPage from "./components/costCenters";
import TripPage from "./components/trips";
import DayViewPage from "./components/dayview/DayViewPage";
import VehicleListPage from "./components/vehicles";
import UserListPage from "./components/users";
import GroupPage from "./components/groups";
import DriveboxListPage from "./components/driveboxes";
import SettingPage from "./components/settings";
import LiveLocationPage from "./components/liveLocation";
import CalendarPage from "./components/calendar/CalendarPage";
import TimeRecordingPage from "./components/timeRecording/TimeRecordingPage";
import ReportPage from "./components/reporting/ReportPage";
import ChargingPage from "./components/chargingSimulator/ChargingPage";

import OrderPage from "./components/order/OrderPage";
import PageNotFound from "./components/common/PageNotFound";

import { renderToStaticMarkup } from "react-dom/server";

import { languageKey } from "./managers/localStorageManager";
import { allowedRoles, isAuthorized } from "./managers/authManager";
import { packages } from "./models/Package";
import { roles } from "./models/Role";
import { languages } from "./models/Language";
import { requestCurrentlyLoggedinUser } from "./actions/actionUser";
import { requestStatus, setLanguage } from "./actions/actionCommon";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { useSnackbar } from "./hooks/useModalStatus";
import Snackbar from "./components/common/views/Snackbar";
import { useHotkeys } from "react-hotkeys-hook";
import "moment/locale/de-at";
import "moment/locale/en-gb";

import moment from "moment";
import {
  getTranslation,
  setTranslatorLanguage,
} from "./translations/translations";
import { addDays, differenceInDays } from "date-fns";
import TestExpiredPage from "./components/testExpired/TestExpiredPage";
import Geofences from "./components/geofences/Geofences";
import GeofencesReport from "./components/geofences/reporting/GeofencesReport";
import GeofencesAnalysis from "./components/geofences/analysis/GeofencesAnalysis";
import HWNODashboard from "./components/hwno/HWNODashboard";
import GroupTripPage from "./components/groupTrips/GroupTripPage";
import ReservationPage from "./components/reservation/ReservationPage";
import QualityChargingPage from "./components/qualityCharging/QualityChargingPage";
import FaultyTripsPage from "./components/faultyTrips/FaultyTripsPage";
import DriverIdentificationPinAssignment from "./components/driverIdentificationPage/DriverIdentificationPinAssignment";
import DriverIdentificationForgotPinPage from "./components/driverIdentificationPage/DriverIdentificationForgotPinPage";
import DriverIdentificationSuccessPage from "./components/driverIdentificationPage/DriverIdentificationSuccessPage";
import DriverIdentificationErrorPage from "./components/driverIdentificationPage/DriverIdentificationErrorPage";

import { useModalStatus } from "./hooks/useModalStatus";
import i18n from "./i18n";


const App = (props) => {

  const { value: savedLanguage, set: saveLanguage } = useLocalStorage( languageKey, props.language.German );
  const {
    open: changeLanguageSnackbar,
    show: showSuccessChangeLanguage,
    hide: hideChangeLanguageSnackbar,
  } = useSnackbar();

  useHotkeys("ctrl+alt+l+d", () => {
    if (savedLanguage !== languages.German)
      handleLanguageChange(languages.German);
  });
  useHotkeys("ctrl+alt+l+e", () => {
    if (savedLanguage !== languages.English)
      handleLanguageChange(languages.English);
  });

  const {
    modal: driverIdentificationAssignModal,
    open: openDriverIdentificationAssignModal,
    close: closeDriverIdentificationAssignModal,
  } = useModalStatus();

  const { me, language } = props;
  const {
    setLanguage,
    requestCurrentlyLoggedinUser,
    requestStatus,
  } = props;
  const { testerSince, testerExpiresInDays } = me;
  const testEndDate = addDays(new Date(testerSince), testerExpiresInDays);
  const testerDayLeft = differenceInDays(testEndDate, new Date());

  function handleLanguageChange(language) {
    props.setLanguage(language);
    showSuccessChangeLanguage();
    setTimeout(() => {
      window.location.reload(true);
    }, 500);
  }

  useEffect(() => {
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }

    if (
      window.location.pathname !== menu.login.path &&
      window.location.pathname !== menu.resetpassword.path
    ) {
      requestCurrentlyLoggedinUser();
      requestStatus();
    }
  }, []);

  useEffect(() => {
    if (language !== savedLanguage) {
      saveLanguage(language);
    }
    i18n.changeLanguage(language)
  }, [language]);

  switch (language) {
    case languages.German:
      moment.locale("de");
      setTranslatorLanguage("de");
      break;
    case languages.English:
      moment.locale("en");
      setTranslatorLanguage("en");
      break;
  }

  function isMomentPassword() {
    if (!me || Object.keys(me).length === 0) return false; //if me not loaded, assume that he is already in the new system
    return me.hasAMomentPassword;
  }

  function getStartingPage() {
    return isAuthorized(me, [roles.SuperAdmin]) ? (
      <MandatorPage />
    ) : (
      <DashboardPage />
    );
  }

  function pageAuthorization(
    page,
    allowedRoles,
    allowedPackages,
    ignorTestExpired
  ) {
    if (isMomentPassword())
      return <Redirect to={menu.resetMomentPassword.path} />;

    if (testerSince && testerDayLeft <= 0 && !ignorTestExpired)
      return <TestExpiredPage />;
    if (isAuthorized(me, allowedRoles, allowedPackages))
      return <LoadingPage>{page}</LoadingPage>;
    return (
      <LoadingPage>
        <PageNotFound />
      </LoadingPage>
    );
  }

  return (
    <div>
      <Router>
        <Switch>
          <Route path={menu.login.path} render={() => <LoginPage />} />
          <Route path={menu.resetpassword.path} render={() => <ResetPage />} />
          <Route
            path={menu.resetMomentPassword.path}
            render={() => <MomentPasswordResetPage />}
          />

          <Route
            exact
            path={menu.rowData.path}
            render={() =>
              pageAuthorization(<RawDataPage />, allowedRoles(roles.SuperAdmin))
            }
          />
          <Route
            exact
            path={menu.mandatorSuperUser.path}
            render={() =>
              pageAuthorization(
                <MandatorSuperUserPage />,
                allowedRoles(roles.SuperAdmin)
              )
            }
          />
          <Route
            exact
            path={menu.mandatorUser.path}
            render={() =>
              pageAuthorization(
                <MandatorUserPage />,
                allowedRoles(roles.SuperAdmin)
              )
            }
          />
          <Route
            exact
            path={menu.mandatorDrivebox.path}
            render={() =>
              pageAuthorization(
                <MandatorDriveboxPage />,
                allowedRoles(roles.SuperAdmin)
              )
            }
          />
          <Route
            exact
            path={menu.mandator.path}
            render={() =>
              pageAuthorization(
                <MandatorPage />,
                allowedRoles(roles.SuperAdmin)
              )
            }
          />

          <Route
            exact
            path={menu.hwnodashboard.path}
            render={() =>
              pageAuthorization(
                <HWNODashboard />,
                allowedRoles(roles.GroupLeaderViewer)
              )
            }
          />
          <Route
            exact
            path={menu.dashboard.path}
            render={() => pageAuthorization(getStartingPage())}
          />
          <Route
            exact
            path={menu.calendar.path}
            render={() =>
              pageAuthorization(
                <CalendarPage />,
                allowedRoles(roles.GroupLeaderViewer),
                [packages.Logbook]
              )
            }
          />
          <Route
            exact
            path={menu.trips.path}
            render={() =>
              pageAuthorization(
                <TripPage />,
                allowedRoles(roles.DriverViewer),
                [packages.Logbook]
              )
            }
          />
          <Route
            exact
            path={menu.dayView.path}
            render={() =>
              pageAuthorization(
                <DayViewPage />,
                allowedRoles(roles.DriverViewer),
                [packages.Tracking]
              )
            }
          />
          <Route
            exact
            path={menu.livelocation.path}
            render={() =>
              pageAuthorization(
                <LiveLocationPage />,
                allowedRoles(roles.GroupLeaderViewer),
                [packages.Tracking]
              )
            }
          />
          <Route
            exact
            path={menu.timeReporting.path}
            render={() =>
              pageAuthorization(
                <TimeRecordingPage />,
                allowedRoles(roles.Driver),
                [packages.TimeLog]
              )
            }
          />
          <Route
            exact
            path={menu.reporting.path}
            render={() =>
              pageAuthorization(
                <ReportPage />,
                allowedRoles(roles.GroupLeaderViewer)
              )
            }
          />
          <Route
            exact
            path={menu.order.path}
            render={() =>
              pageAuthorization(
                <OrderPage />,
                allowedRoles(roles.Admin),
                [],
                true
              )
            }
          />
          <Route
            exact
            path={menu.vehicles.path}
            render={() =>
              pageAuthorization(
                <VehicleListPage />,
                allowedRoles(roles.DriverLeader)
              )
            }
          />
          <Route
            exact
            path={menu.users.path}
            render={() =>
              pageAuthorization(
                <UserListPage />,
                allowedRoles(roles.DriverLeader)
              )
            }
          />
          <Route
            exact
            path={menu.groups.path}
            render={() =>
              pageAuthorization(<GroupPage />, allowedRoles(roles.AdminViewer))
            }
          />
          <Route
            exact
            path={menu.costcenters.path}
            render={() =>
              pageAuthorization(
                <CostCenterListPage />,
                allowedRoles(roles.AdminViewer)
              )
            }
          />

          <Route
            exact
            path={menu.geofences.path}
            render={() =>
              pageAuthorization(<Geofences />, allowedRoles(roles.Admin), [
                packages.Geofences,
              ])
            }
          />

          <Route
            exact
            path={menu.geofencesReport.path}
            render={() =>
              pageAuthorization(
                <GeofencesReport />,
                allowedRoles([roles.Admin, roles.DriverLeader, roles.Driver]),
                [packages.Geofences]
              )
            }
          />

          <Route
            exact
            path={menu.geofenceAnalysis.path}
            render={() =>
              pageAuthorization(
                <GeofencesAnalysis />,
                allowedRoles([roles.Admin, roles.DriverLeader, roles.Driver]),
                [packages.Geofences]
              )
            }
          />

          <Route
            exact
            path={menu.chargingSimulator.path}
            render={() => <ChargingPage />}
          />

          <Route
            exact
            path={menu.driveboxes.path}
            render={() =>
              pageAuthorization(
                <DriveboxListPage />,
                allowedRoles(roles.GroupLeaderViewer)
              )
            }
          />
          <Route
            exact
            path={menu.settings.path}
            render={() =>
              pageAuthorization(
                <SettingPage />,
                allowedRoles(roles.DriverViewer)
              )
            }
          />
          <Route
            exact
            path={menu.groupTrip.path}
            render={() =>
              pageAuthorization(
                <GroupTripPage />,
                allowedRoles(roles.DriverViewer)
              )
            }
          />
          <Route
            exact
            path={menu.reservation.path}
            render={() =>
              pageAuthorization(
                <ReservationPage />,
                allowedRoles(roles.DriverViewer),
                [packages.Reservations]
              )
            }
          />
          <Route
            exact
            path={menu.faultyTrips.path}
            render={() =>
              pageAuthorization(
                <FaultyTripsPage />,
                allowedRoles(roles.SuperAdmin)
              )
            }
          />
          <Route
            exact
            path={menu.qualityCharging.path}
            render={() =>
              pageAuthorization(
                <QualityChargingPage />,
                allowedRoles(roles.Admin),
                [packages.QualityCharging]
              )
            }
          />

          {/* Region DriverIdentification */}

          <Route
            exact
            path={menu.driverIdentification.path}
            render={() => (
              <DriverIdentificationPinAssignment
                assignModal={driverIdentificationAssignModal}
                openAssignModal={openDriverIdentificationAssignModal}
                closeAssignModal={closeDriverIdentificationAssignModal}
              />
            )}
          />

          <Route
            exact
            path={menu.forgotPin.path}
            render={() => (
              <DriverIdentificationForgotPinPage
                assignModal={driverIdentificationAssignModal}
                openAssignModal={openDriverIdentificationAssignModal}
                closeAssignModal={closeDriverIdentificationAssignModal}
              />
            )}
          />

          <Route
            exact
            path={
              menu.driverIdentificationSuccess.path
            }
            component={DriverIdentificationSuccessPage}
          />

          <Route
            exact
            path={
              menu.driverIdentificationError.path
            }
            component={DriverIdentificationErrorPage}
          />

          {/* End Region DriverIdentification */}

          <Route render={() => <PageNotFound />} />
        </Switch>
      </Router>
      <Snackbar
        translateKey="setting.changeLanguage"
        onClose={hideChangeLanguageSnackbar}
        open={changeLanguageSnackbar}
      />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { requestStatus, requestCurrentlyLoggedinUser, setLanguage },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    me: state.users.me,
    authorised: state.authorised,
    authentication: state.authentication,
    language: state.language,
    status: state.status,
  };
}

export default connect(mapStateToProp, mapDispatchToProps)(App);

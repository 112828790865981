import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Typography, Box } from "@material-ui/core";
import {
  requestVehicleNameById,
  clearDriverName,
  requestDriverNameByPin,
  requestAssignVehicleToDriverByPin,
  setVehicleId,
  loadDriverPin
} from "../../actions/actionDriverIdentification";

import DriverIdentification from "./DriverIdentification";
import PinInput from "./PinInput/PinInput";
import DriverIdentificationConditionalLink from "./DriverIdentificationConditionalLink";
import DriverIdentificationAssignModal from "./DriverIdentificationAssignModal/DriverIdentificationAssignModal";

import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import { RootState } from "../../store";
import LoadingIndicator from "../common/LoadingIndicator";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles<Theme>((theme) => ({
  contentContainer: {
    textAlign: "center",
  },
  pageContainer: {
    padding: "10vh 50px 50px 50px",
    width: "100%",
    backgroundColor: "#374678",
    color: "white",
    display: "flex",

    [theme.breakpoints.up("lg")]: {
      padding: "20vh 50px 50px 50px",
    },
  },
}));

type DriverIdentificationPinAssignmentProps = {
  assignModal: boolean,
  openAssignModal: () => void,
  closeAssignModal: () => void,
}

type Params = {
  vehicleId: string,
}

const DriverIdentificationPinAssignment: React.FC<DriverIdentificationPinAssignmentProps> = ({
  assignModal,
  openAssignModal,
  closeAssignModal,
}) => {
  const pinLength = 4;
  const [pin, setPin] = useState(Array(pinLength).fill(""));
  const { vehicleId } = useParams<Params>();
  const pathname = `driverIdentification/${vehicleId}`;
  const [currentIndex, setCurrentIndex] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const {vehicleDriver , driverName, vehicleName, loading} = useSelector((state: RootState) => ({
    vehicleDriver: state.driverIdentification.vehicleDriver,
    driverName: state.driverIdentification.driverName,
    vehicleName: state.driverIdentification.vehicleName,
    loading: state.driverIdentification.loading
  }));

  useEffect(() => {
    !vehicleName && dispatch(requestVehicleNameById(vehicleId));

    dispatch(setVehicleId(vehicleId));
  }, []);

  useEffect(() => {
    vehicleDriver && history.push(`/${pathname}/success`);
  }, [vehicleDriver]);

  useEffect(() => {
    if (pin[pinLength - 1]) {
      const concatenatedPin = pin.join("");
      dispatch(loadDriverPin());
      dispatch(requestDriverNameByPin({ vehicleId, pin: concatenatedPin }));
      openAssignModal();
    }

    return () => closeAssignModal();
  }, [pin]);

  const onHandleCancel = () => {
    setPin(Array(pinLength).fill(""));
    setCurrentIndex(0);
    dispatch(clearDriverName());
    closeAssignModal();
  };

  const onHandleConfirm = () => {
    closeAssignModal();
    const concatenatedPin = pin.join("");
    dispatch(requestAssignVehicleToDriverByPin({vehicleId, pin: concatenatedPin}));
  }

  return (   
      <DriverIdentification>
        {
          loading ? 
          <Box className={classes.pageContainer}>
            <LoadingIndicator color={"white"} />
          </Box> :
        <>
          <Grid item xs={10} className={classes.contentContainer}>
          {vehicleName && (
            <Typography variant="subtitle2" style={{ color: "#B0BEC5" }}>
             {t("driverIdentification.VehicleConfirmationMessage", {vehicleName: vehicleName})}
            </Typography>
          )}
          </Grid>
          <Grid item xs={10} className={classes.contentContainer}>
            <PinInput length={pinLength} pin={pin} setPin={setPin} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
          </Grid>
          <Grid item xs={10} className={classes.contentContainer}>
            <DriverIdentificationConditionalLink pin={pin} pinLength={pinLength} pathname={pathname} />
          </Grid>
          <DriverIdentificationAssignModal
          assignModal={assignModal}
          vehicleName={vehicleName}
          driverName={driverName}
          onHandleCancel={onHandleCancel}
          onHandleConfirm={onHandleConfirm}
          />
        </>
        }
       </DriverIdentification>
  );
};

export default DriverIdentificationPinAssignment;
